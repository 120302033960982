import { Image, Text, View } from 'react-native';
import React from 'react';
import Octagon from '../app/Octagon';

interface Props {
  title: string;
  content: string[];
  price?: string;
  size: number;
}

const MIN_SIZE = 320;
const MAX_SIZE = 360;

const Package = ({ title, content, price, size }: Props) => {
  const sizeToUse = size < MIN_SIZE ? MIN_SIZE : size > MAX_SIZE ? MAX_SIZE : size;

  return (
    <View
      style={{
        width: sizeToUse,
        height: sizeToUse,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        marginBottom: 50,
      }}
    >
      <Octagon size={sizeToUse} />
      <View
        style={{
          width: 40,
          height: 40,
          backgroundColor: 'red',
          borderRadius: 25,
          marginBottom: 10,
        }}
      >
        <Image
          source={require('../../assets/menu-coaching.png')}
          style={{
            width: 40,
            height: 40,
            resizeMode: 'contain',
          }}
        />
      </View>
      <Text
        style={{
          color: '#d60006',
          fontSize: 16,
          fontWeight: '400',
          lineHeight: 28,
          textAlign: 'center',
          fontFamily: 'gabriela',
          marginBottom: 6,
        }}
      >
        {title}
      </Text>
      {content.map((text) => {
        return (
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              lineHeight: 28,
              textAlign: 'center',
              fontFamily: 'gabriela',
            }}
          >
            {text}
          </Text>
        );
      })}
      {price && (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '800',
            lineHeight: 28,
            textAlign: 'center',
            fontFamily: 'gabriela',
            marginTop: 6,
          }}
        >
          {price}
        </Text>
      )}
    </View>
  );
};

export default Package;
