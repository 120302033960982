import React, { useMemo } from 'react';
import { View, useWindowDimensions } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import CloudOne from './CloudOne';
import CloudTwo from './CloudTwo';

const OverlayingClouds = () => {
  const { width, height } = useWindowDimensions();

  const memoizedClouds = useMemo(() => {
    return (
      <View key={'memoizedclouds'} pointerEvents="none" style={{ width, height, position: 'absolute' }}>
        <CloudOne />
        <CloudTwo />
      </View>
    );
  }, []);

  return memoizedClouds;
};

export default OverlayingClouds;
