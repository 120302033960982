import { Image, Linking, ScrollView, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import React, { useEffect } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const About = ({ isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();

  const mounted = useSharedValue(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  return (
    <Animated.View
      style={[{ position: 'absolute', width: '100%', paddingVertical: 100 }, animatedFadeInStyle]}
    >
      <ScrollView
        style={{
          height: height - 240,
        }}
      >
        <Animated.View
          style={[
            {
              width: '80%',
              marginLeft: '10%',
              position: 'absolute',
            },
          ]}
        >
          <Image
            style={{
              height: width > 1000 ? 400 : 300,
              marginTop: 20,
              width: '200%',
              marginRight: '25%',
              right: '35%',
            }}
            resizeMode="contain"
            source={require('../../assets/annemarie.png')}
          />
          <Text
            style={{
              color: '#d60006',
              fontSize: 20,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Hoi!
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            <Text style={{ fontWeight: '800' }}>
              Ik ben Annemarie Koornstra en ik ben oprichtster van Sensee. Mijn passie is om mensen te helpen
              zichzelf te zijn en ze te laten stralen. Ze te begeleiden naar hun ware essentie, het hogere
              Zelf en die verbinding te versterken in het dagelijks leven. <br />
            </Text>
            <br />
            Ik ben geboren en getogen in Best. Hier woon ik nog steeds met veel plezier samen met mijn
            verloofde en twee katten.
            <br />
            <br />
            Ik ben sinds mijn kindertijd al gefocust op zelfontwikkeling, zelfliefde en verbinding leggen met
            het hogere Zelf. Na het meemaken van een aantal traumatische gebeurtenissen ben ik op zoek gegaan
            naar heling, liefde en geluk. In mijn zoektocht ben ik vele verschillende reguliere en
            alternatieve therapieën tegengekomen. Vanuit iedere soort therapie heb ik handvatten ontvangen die
            me hebben geholpen. Daarna ben ik me ook verder gaan verdiepen in de diverse vormen van
            communicatie en heling. Hieruit is mijn passie geboren om anderen te ondersteunen in hun proces en
            te begeleiden naar de verbinding met de bron.
            <br />
            <br />
            Deze passie kan ik uiten in verschillende vormen, denk hierbij aan 1-op-1 coachingsessies of een
            Kundalini NRGY Awakening sessie. Ook wil ik spiritualiteit verder de wereld inbrengen door middel
            van het creëren van content, denk hierbij aan foto’s, video’s en andere visuele uitingen. Door de
            variëteit waarin ik mijn passie kan uitvoeren kan ik ook van veel middelen gebruik maken om te
            begeleiden en de connectie op te zoeken. Terug naar de kern met een liefdevolle uitstraling is
            waar mijn hart sneller van gaat kloppen.
            <br />
            <br />
            Laat me je ondersteunen naar het licht dat in jouw hart zit!
            <br />
          </Text>

          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://www.annemarie-sensee.nl/algemene-voorwaarden-sensee.pdf');
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: '400',
                marginBottom: 30,
                lineHeight: 28,
                fontFamily: 'gabriela',
                color: '#d60006',
              }}
            >
              Download hier de algemene voorwaarden
            </Text>
          </TouchableOpacity>
        </Animated.View>
      </ScrollView>
    </Animated.View>
  );
};

export default About;
