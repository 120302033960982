import {
  Image,
  Linking,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
  useWindowDimensions,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import HexaButton from '../app/HexaButton';
import { MaterialIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import Popup from '../app/Popup';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const Contact = ({ isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();

  const [formSent, setFormSent] = useState(false);

  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState(null);
  const mounted = useSharedValue(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  const onSubmit = async () => {
    console.log('Sending....');
    const formData = new FormData();

    formData.append('access_key', '3d3439ec-645a-4055-825d-bed7539703fe');
    formData.append('email', email);
    formData.append('name', name);
    formData.append('message', message);

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setFormSent(true);
      setEmail('');
      setName('');
      setMessage('');
      console.log('Form Submitted Successfully');
    } else {
      setFormSent(false);
      setError(data.message);

      console.log('Error', data);
      console.log(data.message);
    }
  };

  return (
    <>
      <Animated.View
        style={[{ position: 'absolute', width: '100%', paddingVertical: 100 }, animatedFadeInStyle]}
      >
        <ScrollView style={{ height }} contentContainerStyle={{ alignItems: 'center' }}>
          <Animated.View
            style={[
              {
                width: '80%',
                position: 'absolute',
                maxWidth: 1000,
                marginBottom: 200,
                paddingBottom: 200,
                flexDirection: width > 1000 ? 'row' : 'column',
              },
            ]}
          >
            <View
              style={{
                flex: 1,
                paddingHorizontal: 40,
              }}
            >
              <Image
                style={{
                  height: width > 1000 ? 400 : 300,
                  marginTop: 20,
                  left: 0,
                }}
                resizeMode="contain"
                source={require('../../assets/annemarie-coaching.png')}
              />
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: '400',
                  marginBottom: 30,
                  lineHeight: 28,
                  fontFamily: 'gabriela',
                }}
              >
                Wil je even sparren of meer informatie? Laat het me weten. Vul het contactformulier in of
                stuur me een e-mail. Ik ben ook goed bereikbaar via Instagram. <br />
                <br />
                Liefs, Annemarie
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <MaterialIcons name="email" size={24} color="#d60006" />
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '400',
                    lineHeight: 28,
                    fontFamily: 'gabriela',
                    marginLeft: 10,
                  }}
                >
                  info@annemarie-sensee.nl
                </Text>
              </View>
              <Pressable
                onPress={() => {
                  Linking.openURL('https://instagram.com/annemarie_sensee');
                }}
                style={{ marginTop: 10, flexDirection: 'row' }}
              >
                <Entypo name="instagram" size={24} color="#d60006" />
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '400',
                    lineHeight: 28,
                    fontFamily: 'gabriela',
                    marginLeft: 10,
                  }}
                >
                  annemarie_sensee
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  borderLeftWidth: width > 1000 ? 1 : 0,
                  borderTopWidth: width > 1000 ? 0 : 1,
                  borderStyle: 'dotted',
                  borderColor: 'red',
                  borderLeftColor: '#d60006',
                  paddingHorizontal: 40,
                  marginVertical: 20,
                  paddingVertical: 20,
                }}
              >
                <Text
                  style={{
                    color: '#d60006',
                    fontSize: 20,
                    fontWeight: '600',
                    marginBottom: 10,
                    fontFamily: 'gabriela',
                  }}
                >
                  Contactformulier
                </Text>

                <View
                  style={{
                    backgroundColor: 'white',
                    padding: 10,
                    marginVertical: 10,
                    alignItems: 'center',
                  }}
                >
                  <View style={{ borderColor: '#f60006', borderWidth: 3, flex: 1, width: '100%' }}>
                    <TextInput
                      inputMode="text"
                      placeholder="Naam..."
                      value={name}
                      onChangeText={setName}
                      style={{
                        padding: 10,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: 'white',
                    padding: 10,
                    marginVertical: 10,
                    alignItems: 'center',
                  }}
                >
                  <View style={{ borderColor: '#f60006', borderWidth: 3, flex: 1, width: '100%' }}>
                    <TextInput
                      inputMode="email"
                      keyboardType="email-address"
                      textContentType="emailAddress"
                      placeholder="Email..."
                      value={email}
                      onChangeText={setEmail}
                      style={{
                        padding: 10,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: 'white',
                    padding: 10,
                    marginVertical: 10,
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <View style={{ borderColor: '#f60006', borderWidth: 3, flex: 1, width: '100%' }}>
                    <TextInput
                      inputMode="text"
                      placeholder="Bericht of vraag..."
                      value={message}
                      onChangeText={setMessage}
                      numberOfLines={10}
                      multiline
                      style={{
                        padding: 10,
                        textAlignVertical: 'top',
                        alignItems: 'flex-start',
                        minHeight: 200,
                      }}
                    />
                  </View>
                </View>
                <HexaButton
                  style={{
                    marginTop: 10,
                  }}
                  disabled={!email || !name || !message}
                  text="Verzenden"
                  onPress={() => {
                    if (!email || !name || !message) return;
                    onSubmit();
                  }}
                />
              </View>
            </View>
          </Animated.View>
        </ScrollView>
      </Animated.View>
      {formSent && (
        <View
          style={{
            position: 'absolute',
            justifyContent: 'center',
          }}
        >
          <Popup
            title={error ? 'Oeps! Er ging iets mis' : 'Verzonden!'}
            text={
              error
                ? 'Probeer het later nog eens of contacteer me via mail of Instagram!'
                : 'Bedankt voor je bericht. Ik neem zo snel mogelijk contact met je op.'
            }
            ctaIcon="close"
            onCtaPress={() => {
              setFormSent(false);
            }}
          />
        </View>
      )}
    </>
  );
};

export default Contact;
