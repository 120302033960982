import { ScrollView, Text, View, useWindowDimensions, FlatList, Linking } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import HexaButton from '../app/HexaButton';
import Popup from '../app/Popup';
import { FirebaseContext, getEventsFromFirebase } from '../../services/FirebaseProvider';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const Agenda = ({ isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();
  const { db } = useContext(FirebaseContext);
  const [events, setEvents] = useState<EventType[]>([]);

  useEffect(() => {
    if (!db) return;
    const getData = async () => {
      const checklist = (await getEventsFromFirebase(db)) as EventType[];

      const filteredChecklist = checklist
        .filter((item) => item.active)
        .sort((a, b) => a.sortOrder - b.sortOrder);

      setEvents(filteredChecklist);
    };
    getData();
  }, [db]);

  const mounted = useSharedValue(false);

  const [showPopup, setShowPopup] = useState(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  interface EventType {
    date: string;
    title: string;
    timeFrom: string;
    timeUntil: string;
    location: string;
    city: string;
    price: string;
    bookingUrl: string;
    active: boolean;
    sortOrder: number;
  }

  const renderItem = ({ item }: { item: EventType }) => {
    return (
      <View style={{ marginVertical: 10 }}>
        <Text
          style={{
            fontSize: 16,
            fontFamily: 'gabriela',
            fontWeight: '600',
            marginVertical: 4,
          }}
        >
          {item.title}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 4,
            fontFamily: 'gabriela',
          }}
        >
          {item.date}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 4,
            fontFamily: 'gabriela',
          }}
        >
          {item.timeFrom} - {item.timeUntil}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 4,
            fontFamily: 'gabriela',
          }}
        >
          {item.location}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 4,
            fontFamily: 'gabriela',
          }}
        >
          {item.city}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 4,
            fontFamily: 'gabriela',
          }}
        >
          {item.price}
        </Text>
        {item.bookingUrl && (
          <View style={{ marginTop: 10 }}>
            <HexaButton
              text="Reserveren"
              onPress={() => {
                Linking.openURL(item.bookingUrl);
              }}
            />
          </View>
        )}
        <View
          style={{
            borderWidth: 1,
            borderStyle: 'dotted',
            borderColor: 'red',
            borderTopColor: '#d60006',
            marginTop: 20,
          }}
        />
      </View>
    );
  };

  return (
    <Animated.View
      style={[{ position: 'absolute', width: '100%', paddingVertical: 100 }, animatedFadeInStyle]}
    >
      <ScrollView
        style={{
          height: height - 240,
        }}
      >
        <Animated.View
          style={[
            {
              width: '80%',
              marginLeft: '10%',
              position: 'absolute',
              paddingTop: 50,
            },
          ]}
        >
          <Text
            style={{
              color: '#d60006',
              fontSize: 20,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Agenda
          </Text>
          <FlatList data={events} renderItem={renderItem} />
          <Text
            style={{
              fontSize: 16,
              fontFamily: 'gabriela',
              fontWeight: '600',
              marginVertical: 24,
              lineHeight: 32,
            }}
          >
            Ander moment of privésessie boeken? Of gewoon even kennismaken? Stuur me een berichtje via{' '}
            <Text
              onPress={() => {
                Linking.openURL(
                  'https://instagram.com/annemarie_sensee?utm_source=qr&igshid=dDZubjZxOXJzcnds'
                );
              }}
              style={{
                color: '#d60006',
              }}
            >
              Instagram
            </Text>{' '}
            of een email naar{' '}
            <Text
              onPress={() => {
                Linking.openURL('mailto:info@annemarie-sensee.nl');
              }}
              style={{
                color: '#d60006',
              }}
            >
              info@annemarie-sensee.nl
            </Text>
            !
          </Text>
        </Animated.View>
      </ScrollView>
      {showPopup && (
        <View style={{ position: 'absolute', width, height }}>
          <Popup
            onClose={() => {
              setShowPopup(false);
            }}
            title="Wat leuk dat je een kundalini NRGY Awakening sessie wilt boeken!"
            text="Reserveer je plek door een email te sturen naar info@annemarie-sensee.nl of stuur een DM via
          Instagram!"
          />
        </View>
      )}
    </Animated.View>
  );
};

export default Agenda;
