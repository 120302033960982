import { View, Text } from 'react-native';
import React, { useEffect } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

const CloudOne = () => {
  const cloudOneLeftValue = useSharedValue(-400);

  useEffect(() => {
    cloudOneLeftValue.value = withRepeat(
      withTiming(-800, { duration: 8000, easing: Easing.bezier(0.25, 0.1, 0.25, 0.1) }),
      // withSpring(-400, { damping: 10, mass: 10, stiffness: 100, velocity: 0.5 }),
      -1,
      true
    );
  }, []);

  const cloudOneStyle = useAnimatedStyle(() => {
    return {
      left: cloudOneLeftValue.value,
    };
  });

  return (
    <Animated.Image
      style={[
        {
          width: 800,
          height: 400,
          position: 'absolute',
          top: 500,
          left: -200,
          zIndex: 999,
        },
        cloudOneStyle,
      ]}
      resizeMode="contain"
      source={require('../../assets/wolk1.png')}
    />
  );
};

export default CloudOne;
