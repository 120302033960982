import { View, Text } from 'react-native';
import React from 'react';

const OctagonShape = ({ size, backgroundColor }: { size: number; backgroundColor: string }) => {
  return (
    <View
      style={{
        position: 'absolute',
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          position: 'absolute',
          width: size,
          height: size / 2.42,
          backgroundColor,
        }}
      />
      <View
        style={{
          position: 'absolute',
          width: size,
          height: size / 2.42,
          backgroundColor,
          transform: [{ rotate: `${45}deg` }],
        }}
      />
      <View
        style={{
          position: 'absolute',
          width: size,
          height: size / 2.42,
          backgroundColor,
          transform: [{ rotate: '90deg' }],
        }}
      />
      <View
        style={{
          position: 'absolute',
          width: size,
          height: size / 2.42,
          backgroundColor,
          transform: [{ rotate: '135deg' }],
        }}
      />
    </View>
  );
};

export default OctagonShape;
