import { View, Text, Image } from 'react-native';
import React from 'react';

const Review = ({ text }: { text: string }) => {
  return (
    <View
      style={{
        backgroundColor: 'white',
        padding: 10,
        marginVertical: 20,
        alignItems: 'center',
      }}
    >
      <View style={{ borderColor: '#f60006', borderWidth: 3, padding: 30 }}>
        <Text style={{ fontFamily: 'gabriela', fontSize: 16 }}>{text}</Text>
      </View>
      <View
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          top: 0,
          zIndex: 0,
        }}
      >
        <Image
          source={require('../../assets/kundalini-snake.png')}
          style={{
            width: 50,
            height: 50,
            resizeMode: 'contain',
            marginTop: -25,
          }}
        />
      </View>
    </View>
  );
};

export default Review;
