import React, { ReactNode, createContext, useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, Firestore, collection, getDocs } from 'firebase/firestore/lite';

type FirebaseContexModel = {
  db: Firestore | undefined;
};

export const FirebaseContext = createContext<FirebaseContexModel>({
  db: undefined,
});

export const getEventsFromFirebase = async (db: Firestore) => {
  const checklistCol = collection(db, 'events');
  const checklistSnapshot = await getDocs(checklistCol);
  return checklistSnapshot.docs.map((doc) => doc.data());
};

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyANd-HJmG3YR5uuZKn6Bz9tX_9B4hEZE9Q',
  authDomain: 'annemarie-sensee.firebaseapp.com',
  projectId: 'annemarie-sensee',
  storageBucket: 'annemarie-sensee.appspot.com',
  messagingSenderId: '941687879213',
  appId: '1:941687879213:web:8a0a35ff2f8a9ad661eaa4',
  measurementId: 'G-V1RPG2358M',
};

export function FirebaseProvider({ children }: { children: ReactNode }) {
  const [db, setDb] = useState<Firestore | undefined>();

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const database = getFirestore(app);
    setDb(database);
  }, []);

  return <FirebaseContext.Provider value={{ db }}>{children}</FirebaseContext.Provider>;
}
