import { Image, Linking, ScrollView, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import React, { useEffect } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import Octagon from '../app/Octagon';
import Package from './Package';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const Coaching = ({ isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();

  const mounted = useSharedValue(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  const packages = [
    {
      title: 'Pakket 1',
      content: ['Kennismaking (30 min)', 'Intakegesprek (1,5u)', '3 coachafspraken (6u)'],
      price: '€ 450,-',
    },
    {
      title: 'Pakket 2',
      content: [
        'Kennismaking (30 min)',
        'Intakegesprek (1,5u)',
        '5 coachafspraken (10u)',
        '1 Kundalini NRGY sessie',
      ],
      price: '€ 600,-',
    },
    {
      title: 'Pakket 3',
      content: [
        'Kennismaking (30 min)',
        'Intakegesprek (1,5u)',
        '10 coachafspraken (20u)',
        '3 Kundalini NRGY sessie',
      ],
      price: '€ 1200,-',
    },
    {
      title: 'Tarieven:',
      content: ['Vervolggesprek(ken):', '€120 / 2 uur', '1-op-1 Kundalini NRGY sessie:', '€200,-'],
    },
  ];

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  const packageWidth = width * 0.8;

  return (
    <Animated.View
      style={[{ position: 'absolute', width: '100%', paddingVertical: 100 }, animatedFadeInStyle]}
    >
      <ScrollView
        style={{
          // height: height - 240,
          height,
        }}
        contentContainerStyle={{
          alignItems: 'center',
        }}
      >
        <Animated.View
          style={[
            {
              width: '80%',
              position: 'absolute',
              maxWidth: 1000,
              paddingBottom: 300,
            },
          ]}
        >
          <Image
            style={{
              height: width > 1000 ? 400 : 300,
              marginTop: 20,
              width: '200%',
              marginRight: '25%',
              right: '35%',
            }}
            resizeMode="contain"
            source={require('../../assets/annemarie-coaching.png')}
          />
          <Text
            style={{
              color: '#d60006',
              fontSize: 20,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Coaching
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            <Text style={{ fontWeight: '800' }}>
              Een goede mentale/ emotionele balans is in de hedendaagse samenleving enorm belangrijk. Het kan
              een flinke uitdaging zijn om jezelf overeind te houden in alle hectiek van de dag; Misschien
              ervaar je veel stress van het werk, of is er juist in de privésfeer veel aan de hand, met je
              relatie, vrienden, familie? Misschien ben je nog druk op zoek naar wie je bent, of wie je wilt
              zijn? Of misschien een combinatie van al het bovenstaande? <br />
            </Text>
            <br />
            Soms komt er zoveel tegelijk op je af dat je verdwaald kunt raken in alle prikkels, keuzes en
            emoties, wat onder andere kan leiden tot de volgende symptomen: <br />
            <br />
            - Moeheid <br />
            - Slapeloosheid - Futloos <br />
            - Hoofdpijn <br />
            - Hartkloppingen <br />
            - Maagpijn/darmklachten <br />- Hyperventileren <br />
            <br />
            Ik ben ervan overtuigd dat lichamelijke klachten een uiting kunnen zijn van een mentale of
            emotionele disbalans. Deze lichamelijke klachten gaan namelijk vaak samen met mentale klachten.
            Wellicht herken je de volgende symptomen bij jezelf: <br />
            <br />
            - Angstig <br />
            - Prikkelbaar <br />
            - Gevoelens van eenzaamheid <br />
            - Snel huilen <br />
            - Somberheid <br />
            - Piekeren <br />
            - Geen concentratie/vergeetachtig <br />
            - Rusteloosheid <br />
            <br />
            Dit zijn aanwijzingen dat er een disbalans is op mentaal of emotioneel vlak.
            <br />
            <br />
            Gelukkig kan deze balans hersteld worden en ik ben er om je hierbij te helpen! Ik ga graag met je
            in gesprek om naar de kern te komen en zal je ondersteunen in jouw proces. Ik bied je diverse
            mogelijkheden in de alternatieve geneeswijzen waarin je uiteraard zelf kunt bepalen wat het beste
            bij jou past. Ik nodig je uit om in je spiegel te kijken en je eigen kracht te gaan zien, en om de
            uitdaging aan te gaan, om zo weer sterker en zekerder verder te kunnen!
          </Text>
          <Text
            style={{
              color: '#d60006',
              fontSize: 16,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Manier van werken
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            Uiteraard moet er een goede klik zijn voor we ergens aan beginnen. Vandaar dat er altijd eerst een
            uitgebreid en vrijblijvend kennismakingsgesprek wordt gepland. Dit doe ik het aller-allerliefste
            face-to-face, bijvoorbeeld bij een gezellig koffietentje. Hierin leg ik onder andere uit dat je
            zelf verantwoordelijk bent voor het proces en wat je daarin van mij kan verwachten. Als je met mij
            een traject aangaat, ga je zelf aan de slag met jouw proces. Ik ondersteun en geef je tips waar je
            mee verder kan. Ik geloof in serieuze, diepgaande gesprekken om door de oppervlakte heen te breken
            om de kern op te zoeken, en daar is tijd voor nodig. Daarom kies ik voor langere coachsessies (+/-
            2 uur). Zo voorkomen we dat we op een kritiek punt wellicht moeten stoppen vanwege de tijd, en
            kunnen we langer doorpraten over mogelijke achterliggende problemen. Mijn focus voor jouw
            ondersteuning ligt voornamelijk in het spirituele vlak. Zelf geef ik Kundalini NRGY sessies die
            kunnen helpen bij het vergroten van je zelfvertrouwen, maar er is nog zo veel meer, en ik help je
            graag met mijn uitgebreide netwerk om die hulp te krijgen die bij jou past, ook als ik die niet
            zelf kan bieden. Het gaat immers om jouw proces, en er is zoveel mogelijk als je weet waar je moet
            zijn!
          </Text>
          <Text
            style={{
              color: '#d60006',
              fontSize: 16,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Pakketten
          </Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', columnGap: 80, rowGap: 40 }}>
            {packages.map((p) => {
              return (
                <Package
                  key={p.title}
                  title={p.title}
                  content={p.content}
                  price={p.price}
                  size={packageWidth}
                />
              );
            })}
          </View>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            Een los coaching gesprek is ook mogelijk (€120/ 2 uur). <br />
            <br />
            Ik bied een aantal opties, voel je dat jouw optie er niet tussen zit? Neem dan even contact met me
            op, dan bespreken we samen de mogelijkheden. info@annemarie-sensee.nl <br />
            <br />
            Eventuele reiskosten (buiten Best): €0,45/km
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            Zullen we samen een kopje koffie drinken? Stuur een mailtje naar:
            <br />
            <Text
              onPress={() => {
                Linking.openURL('mailto:info@annemarie-sensee.nl');
              }}
              style={{
                color: '#d60006',
              }}
            >
              info@annemarie-sensee.nl
            </Text>
          </Text>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://www.annemarie-sensee.nl/algemene-voorwaarden-sensee.pdf');
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: '400',
                marginBottom: 30,
                lineHeight: 28,
                fontFamily: 'gabriela',
                color: '#d60006',
              }}
            >
              Download hier de algemene voorwaarden
            </Text>
          </TouchableOpacity>
        </Animated.View>
      </ScrollView>
    </Animated.View>
  );
};

export default Coaching;
