import { Image, ImageBackground, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import React from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

const AppBackground = ({ children }) => {
  const { width } = useWindowDimensions();
  const rotation = useSharedValue(0);

  const mandalaRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${rotation.value}deg` }],
    };
  });

  rotation.value = withRepeat(
    withTiming(360, { duration: 100000, easing: Easing.bezier(0.25, 0.1, 0.25, 0.1) }),
    -1,
    true
  );

  return (
    <ImageBackground
      style={{ flex: 1, width: '100%', height: '100%', overflow: 'hidden' }}
      source={require('../../assets/background.jpg')}
    >
      <View style={{ flex: 1, pointerEvents: 'none' }}>
        <Image
          style={{
            width: 300,
            height: 400,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          resizeMode="contain"
          source={require('../../assets/wolk4.png')}
        />
        {/* MANDALAS */}
        <Animated.Image
          style={[
            {
              width: 500,
              height: 400,
              position: 'absolute',
              top: -100,
              left: width - 250,
              opacity: 0.6,
            },
            mandalaRotationStyle,
          ]}
          resizeMode="contain"
          source={require('../../assets/mandala1.png')}
        />
        <Animated.Image
          style={[
            {
              width: 300,
              height: 400,
              position: 'absolute',
              top: 600,
              left: -100,
              opacity: 0.6,
            },
            mandalaRotationStyle,
          ]}
          resizeMode="contain"
          source={require('../../assets/mandala2.png')}
        />
        {width > 1000 && (
          <Animated.Image
            style={[
              {
                width: 800,
                height: 800,
                position: 'absolute',
                top: 0,
                left: width / 2 - 600,
                opacity: 0.3,
              },
              mandalaRotationStyle,
            ]}
            resizeMode="contain"
            source={require('../../assets/mandala3.png')}
          />
        )}
      </View>
      {children}
    </ImageBackground>
  );
};

export default AppBackground;

const styles = StyleSheet.create({});
