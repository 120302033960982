import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import React, { useCallback, useRef } from 'react';
import { AVPlaybackStatus, ResizeMode, Video } from 'expo-av';
import * as ScreenOrientation from 'expo-screen-orientation';
interface Props {
  setShowVideo: (showVideo: boolean) => void;
}

const KundaliniVideo = ({ setShowVideo }: Props) => {
  const { width, height } = useWindowDimensions();

  const videoRef = useRef<Video>(null);

  // const isPlaying = useRef(false);

  // const onPlaybackStatusUpdate = useCallback(async (status: { isPlaying: boolean }) => {
  //   if (status.isPlaying && !isPlaying.current) {
  //     console.log('set isPlaying to TRUE');
  //     console.log('isPlaying.current', isPlaying.current);
  //     // videoRef.current?.presentFullscreenPlayer();
  //     // await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
  //     isPlaying.current = true;
  //   } else if (!status.isPlaying && isPlaying.current) {
  //     console.log('set isPlaying to FALSE');
  //     // await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
  //     videoRef.current?.dismissFullscreenPlayer();
  //     isPlaying.current = false;
  //   }
  // }, []);

  return (
    <View
      style={{
        width,
        height,
        position: 'absolute',
        backgroundColor: '#000000cc',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Video
        source={require('../../assets/kundalini-video.mp4')}
        isMuted={false}
        resizeMode={ResizeMode.CONTAIN}
        shouldPlay
        // onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        style={{ width: '100%', height: 300 }}
        ref={videoRef}
        useNativeControls
        videoStyle={{ width: '90%', marginLeft: '5%' }}
      />
      <Pressable
        onPress={() => {
          setShowVideo(false);
        }}
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          width: 40,
          height: 40,
          borderRadius: 20,
          backgroundColor: '#d60006',
          shadowColor: '#000',
          shadowOffset: { width: -4, height: -4 },
          shadowOpacity: 0.1,
          shadowRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            width: 20,
            height: 2,
            backgroundColor: 'white',
            position: 'absolute',
            transform: [{ rotate: '45deg' }],
          }}
        />
        <View style={{ width: 20, height: 2, backgroundColor: 'white', transform: [{ rotate: '-45deg' }] }} />
      </Pressable>
    </View>
  );
};

export default KundaliniVideo;
