import { Image, Pressable, ScrollView, Text, useWindowDimensions } from 'react-native';
import React, { useEffect } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface Props {
  showVideo: () => void;
  isTransitioning: SharedValue<boolean>;
}

const Kundalini = ({ showVideo, isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();

  const mounted = useSharedValue(false);
  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: width > 1000 ? 0 : 100,
        },
        animatedFadeInStyle,
      ]}
    >
      <ScrollView
        style={{
          height: width > 1000 ? height * 0.7 : height - 240,
          width: width > 1000 ? '50%' : '100%',
        }}
      >
        <Animated.View
          style={[
            {
              width: '80%',
              marginLeft: '10%',
              position: 'absolute',
              marginTop: 50,
              // top: '15%',
            } /*, animatedKundaliniText */,
          ]}
        >
          <Text
            style={{
              color: '#d60006',
              fontSize: 20,
              fontWeight: '600',
              marginBottom: 30,
              fontFamily: 'gabriela',
            }}
          >
            Kundalini NRGY Awakening
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
              fontWeight: '800',
            }}
          >
            Heb jij je ooit afgevraagd of er meer is in het leven? Of er meer potentie in jou zit? Heb je een
            verlangen om jouw kracht te voelen en naar buiten te laten komen? Kundalini NRGY Awakening is een
            energetische ervaring die je opnieuw verbindt met je ware essentie.
          </Text>
          {width < 1000 && (
            <Pressable onPress={showVideo}>
              <Image
                style={{ width: '100%', height: 200 }}
                resizeMode="contain"
                source={require('../../assets/video-on-clouds.png')}
              />
            </Pressable>
          )}
          <Text
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 28,
              fontFamily: 'gabriela',
            }}
          >
            Kundalini is een indiaas woord dat staat voor onze levenskracht Prana, Chi of Qi. Kundalini wordt
            in Indiase yoga en tantrische geschriften beschreven als een spirituele/bovennatuurlijke kracht in
            de mens. Het wordt gezien als een ‘oerenergie’ of ‘latente mystieke kracht in het lichaam’. <br />
            <br />
            Het is de bron van ons leven die altijd bij je is, en is geweest. We hebben het potentieel om deze
            levenskracht op andere en diepgaandere manieren dan ooit tevoren te ervaren. Manieren die ons
            terugbrengen naar levensvreugde: The joy of being alive. <br />
            <br />
            Kundalini NRGY Awakening ruimt, in fasen, op wat ons ervan weerhoudt onze essentie ten volle te
            ervaren. Als die essentie of het Zelf wordt ervaren, en de energie vrij door al onze
            energiekanalen (Nadi’s) en chakra’s stroomt, beseffen we onze heelheid/eenheid.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            DISCLAIMER
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '400',
              marginBottom: 30,
              lineHeight: 20,
              fontFamily: 'gabriela',
            }}
          >
            Door deel te nemen aan een Kundalini NRGY sessie van Annemarie Koornstra, verklaar je dat je de
            volgende disclaimer hebt gelezen en ermee akkoord gaat. Kundalini NRGY is niet wetenschappelijk
            onderzocht of op enigerlei wijze bewezen en alle resultaten en claims zijn alleen gebaseerd op
            ervaringen. Kundalini NRGY kan zowel fysieke bewegingen geven als een mogelijkheid tot verbeterd
            mentaal, fysiek en emotioneel welzijn. Zoals bij elke (fysieke) activiteit is het risico op
            blessures altijd aanwezig en niet uit te sluiten. Luister altijd naar jouw eigen lichaam en vraag
            zo nodig steun aan de begeleider. Je bent zelf volledig verantwoordelijk voor alle schade die door
            deelname kan ontstaan. Kundalini NRGY is geen vervanging voor medische of psychologische hulp,
            onderzoek, diagnose of behandeling. Kundalini NRGY wordt niet aanbevolen en is niet veilig voor
            mensen die gevoelig zijn voor ernstige mentale instabiliteit, manie of psychose. Kundalini NRGY
            wordt niet aanbevolen in het laatste trimester van de zwangerschap. Door deel te nemen aan de
            sessie, bevestig je dat je in goede gezondheid verkeert en in de fysieke en mentale conditie bent
            om deel te nemen. Je gaat er hierbij mee akkoord onherroepelijk afstand te doen van alle claims
            die je nu of in de toekomst tegen Kundalini NRGY, Annemarie Koornstra, of een van haar
            facilitators hebt. Jouw e-mail zal alleen worden gebruikt voor communicatiedoeleinden van
            Kundalini NRGY.
          </Text>
        </Animated.View>
      </ScrollView>

      {width > 1000 && (
        <Pressable style={{ height, width: '50%' }} onPress={showVideo}>
          <Image
            style={{ width: '100%', height: '100%' }}
            resizeMode="contain"
            source={require('../../assets/video-on-clouds.png')}
          />
        </Pressable>
      )}
    </Animated.View>
  );
};

export default Kundalini;
