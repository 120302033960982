import { Image, ImageSourcePropType, StyleSheet, Text, TouchableOpacity } from 'react-native';
import React from 'react';
import Animated, { useAnimatedStyle, withDelay, withTiming } from 'react-native-reanimated';

interface Props {
  image: ImageSourcePropType;
  onPress: () => void;
  isMenuOpen: boolean;
  index: number;
  text: string;
}

const MenuItem = ({ image, onPress, isMenuOpen, index, text }: Props) => {
  const animatedMenuItemStyle = useAnimatedStyle(() => {
    return {
      bottom: withDelay((index + 1) * 20, withTiming(isMenuOpen ? 70 * (index + 1) : 0, { duration: 600 })),
    };
  }, [isMenuOpen]);

  const animatedMenuIconStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: withDelay((index + 1) * 50, withTiming(isMenuOpen ? `0deg` : '-90deg', { duration: 600 })),
        },
      ],
    };
  }, [isMenuOpen]);

  const animatedMenuText = useAnimatedStyle(() => {
    return {
      opacity: withDelay((index + 1) * 50, withTiming(isMenuOpen ? 1 : 0, { duration: 600 })),
    };
  }, [isMenuOpen]);

  return (
    <Animated.View style={[{ position: 'absolute' }, animatedMenuItemStyle]}>
      <TouchableOpacity
        style={{
          width: 60,
          height: 60,
          borderRadius: 40,
          backgroundColor: '#d60006',
          shadowColor: '#000',
          shadowOffset: { width: 4, height: 4 },
          shadowOpacity: 0.1,
          shadowRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 0,
        }}
        onPress={() => {
          onPress();
        }}
      >
        <Animated.Image
          style={[
            {
              width: 50,
              height: 50,
              opacity: 1,
            },
            animatedMenuIconStyle,
          ]}
          source={image}
        />
        <Animated.Text
          style={[
            {
              fontFamily: 'gabriela',
              fontWeight: 'bold',
              textAlign: 'right',
              position: 'absolute',
              right: 70,
              width: 300,
            },
            animatedMenuText,
          ]}
        >
          {text}
        </Animated.Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

export default MenuItem;
