import { View, Text, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import React from 'react';

interface Props {
  text: string;
  onPress: () => void;
  inverted?: boolean;
  style?: ViewStyle;
  disabled?: boolean;
}

const HEGAGON_HEIGHT = 60;

const HexaButton = ({ text, onPress, inverted, style, disabled }: Props) => {
  return (
    <TouchableOpacity
      style={[
        {
          height: HEGAGON_HEIGHT,
          backgroundColor: inverted ? 'white' : '#d60006',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 19,
          alignSelf: 'flex-start',
          opacity: disabled ? 0.5 : 1,
        },
        style,
      ]}
      disabled={disabled}
      onPress={onPress}
    >
      <View
        style={{
          width: HEGAGON_HEIGHT,
          height: 0,
          borderBottomWidth: 20,
          borderBottomColor: inverted ? 'white' : '#d60006',
          borderLeftWidth: 15,
          borderLeftColor: 'transparent',
          borderRightWidth: 15,
          borderRightColor: 'transparent',
          borderStyle: 'solid',
          position: 'absolute',
          transform: [{ rotate: '-90deg' }],
          left: -39,
        }}
      />
      <View
        style={{
          width: HEGAGON_HEIGHT,
          height: 0,
          borderBottomWidth: 20,
          borderBottomColor: inverted ? 'white' : '#d60006',
          borderLeftWidth: 15,
          borderLeftColor: 'transparent',
          borderRightWidth: 15,
          borderRightColor: 'transparent',
          borderStyle: 'solid',
          position: 'absolute',
          transform: [{ rotate: '90deg' }],
          right: -39,
        }}
      />
      <Text
        style={{
          color: inverted ? 'black' : 'white',
          fontWeight: '600',
          fontFamily: 'gabriela',
          fontSize: 18,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default HexaButton;
