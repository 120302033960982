import { Image, Pressable, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import React, { useState } from 'react';
import { Screens } from '../../../App';
import MenuItem from './MenuItem';
import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';

interface Props {
  onPress: (activeState: Screens) => void;
}

const getMenuIcon = (item: string) => {
  switch (item) {
    case 'home':
      return require('../../assets/menu-home.png');
    case 'kundalini':
      return require('../../assets/menu-kundalini.png');
    case 'about':
      return require('../../assets/menu-about.png');
    case 'coaching':
      return require('../../assets/menu-coaching.png');
    case 'content-creation':
      return require('../../assets/menu-content-creator.png');
    case 'agenda':
      return require('../../assets/menu-agenda.png');
    case 'contact':
      return require('../../assets/menu-contact.png');
    case 'reviews':
      return require('../../assets/menu-reviews.png');
    default:
      return require('../../assets/menu-home.png');
  }
};

type MenuItem = {
  key: Screens;
  text: string;
};

const Menu = ({ onPress }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hovering = useSharedValue(false);
  const menuItems: MenuItem[] = [
    { key: 'kundalini', text: 'Kundalini NRGY Awakening' },
    { key: 'coaching', text: 'Coaching' },
    { key: 'about', text: 'Over mij' },
    { key: 'agenda', text: 'Agenda' },
    { key: 'reviews', text: 'Reviews' },
    { key: 'contact', text: 'Contact' },
    // { key: 'home', text: 'Home' },
    // { key: 'content-creation', text: 'Content Creation' },
  ];

  const animatedMenuButtonStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: withTiming(hovering.value ? 1.1 : 1, { duration: 100 }),
        },
        {
          rotate: hovering.value
            ? withRepeat(withTiming(`${360}deg`, { duration: 2000 }), -1)
            : withTiming('0deg', { duration: 300 }),
        },
      ],
    };
  });

  const { width, height } = useWindowDimensions();

  const animatedOpacity = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isMenuOpen ? 0.8 : 0, { duration: 500 }),
    };
  });

  return (
    <>
      <Animated.View
        style={[
          { width, height, backgroundColor: 'white', pointerEvents: isMenuOpen ? 'auto' : 'none' },
          animatedOpacity,
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            setIsMenuOpen(false);
          }}
          style={{ width, height }}
        />
      </Animated.View>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          right: 30,
        }}
      >
        <View
          style={{
            alignItems: 'center',
            position: 'absolute',
            width: 80,
            backgroundColor: 'green',
            bottom: 20,
            pointerEvents: isMenuOpen ? 'auto' : 'none',
          }}
        >
          {menuItems.map(({ key, text }, index) => {
            return (
              <MenuItem
                key={key}
                index={index}
                isMenuOpen={isMenuOpen}
                text={text}
                onPress={() => {
                  onPress(key);
                  setIsMenuOpen(!isMenuOpen);
                }}
                image={getMenuIcon(key)}
              />
            );
          })}
        </View>
        <Pressable
          onHoverIn={() => {
            hovering.value = true;
          }}
          onHoverOut={() => {
            hovering.value = false;
          }}
          style={{
            width: 80,
            height: 80,
            borderRadius: 40,
            backgroundColor: '#d60006',
            shadowColor: '#000',
            shadowOffset: { width: -4, height: -4 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <Animated.Image
            style={[
              {
                width: 50,
                height: 50,
                opacity: 1,
              },
              animatedMenuButtonStyle,
            ]}
            source={getMenuIcon('home')}
          />
        </Pressable>
      </View>
    </>
  );
};

export default Menu;
