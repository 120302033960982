import { View, Text, useWindowDimensions, Image, TouchableOpacity } from 'react-native';
import React from 'react';
import Octagon from './Octagon';
import { AntDesign } from '@expo/vector-icons';

const POPUP_SIZE = 300;

interface Props {
  onClose?: () => void;
  title: string;
  text: string;
  ctaImage?: string;
  ctaIcon?: keyof typeof AntDesign.glyphMap;
  onCtaPress?: () => void;
}

const Popup = ({ onClose, title, text, ctaImage, onCtaPress, ctaIcon }: Props) => {
  const { width, height } = useWindowDimensions();
  return (
    <View
      style={{
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
      }}
    >
      <Octagon selfCenter size={POPUP_SIZE} />
      <View
        style={{
          position: 'absolute',
          height: POPUP_SIZE,
          width: POPUP_SIZE,
          marginTop: -POPUP_SIZE / 16,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          source={require('../../assets/kundalini-snake.png')}
          style={{
            width: POPUP_SIZE / 6,
            height: POPUP_SIZE / 6,
            resizeMode: 'contain',
            marginBottom: POPUP_SIZE / 30,
          }}
        />
        <Text
          style={{
            color: '#d60006',
            fontSize: 16,
            fontWeight: '600',
            fontFamily: 'gabriela',
            textAlign: 'center',
            lineHeight: 20,
            width: POPUP_SIZE / 1.4,
            marginBottom: POPUP_SIZE / 30,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: 12,
            textAlign: 'center',
            width: POPUP_SIZE / 1.4,
            fontWeight: '600',
            fontFamily: 'gabriela',
          }}
        >
          {text}
        </Text>
        {ctaImage && (
          <TouchableOpacity
            onPress={onCtaPress}
            style={{
              // marginTop: POPUP_SIZE / 30,
              backgroundColor: '#d60006',
              width: 40,
              height: 40,
              borderRadius: 20,
              padding: 8,
            }}
          >
            {
              <Image
                source={require('../../assets/instagram.png')}
                style={{
                  width: '100%',
                  height: '100%',
                  resizeMode: 'contain',
                  marginBottom: POPUP_SIZE / 24,
                }}
              />
            }
          </TouchableOpacity>
        )}
        {ctaIcon && (
          <TouchableOpacity
            onPress={onCtaPress}
            style={{
              marginTop: POPUP_SIZE / 30,
              backgroundColor: '#d60006',
              width: 40,
              height: 40,
              borderRadius: 20,
              padding: 8,
            }}
          >
            <AntDesign name={ctaIcon} size={24} color="white" />
          </TouchableOpacity>
        )}
      </View>
      {onClose && (
        <View
          style={{
            position: 'absolute',
            height: POPUP_SIZE * 1.1,
            width: 40,
            transform: [{ rotate: '45deg' }],
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              onClose();
            }}
            style={{
              position: 'absolute',
              top: -20,
              right: 0,
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundColor: '#d60006',
              shadowColor: '#000',
              shadowOffset: { width: -4, height: -4 },
              shadowOpacity: 0.1,
              shadowRadius: 4,
              alignItems: 'center',
              justifyContent: 'center',
              transform: [{ rotate: '45deg' }],
            }}
          >
            <View
              style={{
                width: 20,
                height: 2,
                backgroundColor: 'white',
                position: 'absolute',
                transform: [{ rotate: '45deg' }],
              }}
            />
            <View
              style={{ width: 20, height: 2, backgroundColor: 'white', transform: [{ rotate: '-45deg' }] }}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default Popup;
