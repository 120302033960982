import { View, Text, Image } from 'react-native';
import React from 'react';
import OctagonShape from './OctagonShape';

const Octagon = ({ size, selfCenter }: { size: number; selfCenter?: boolean }) => {
  return (
    <View
      style={{
        position: selfCenter ? undefined : 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{ rotate: '22.5deg' }],
        top: selfCenter ? undefined : size / 2,
      }}
    >
      <OctagonShape size={size} backgroundColor="white" />
      <OctagonShape size={size - 40} backgroundColor="#d60006" />
      <OctagonShape size={size - 46} backgroundColor="white" />
    </View>
  );
};

export default Octagon;
