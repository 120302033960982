import { useFonts } from 'expo-font';
import {
  Button,
  Image,
  Linking,
  Pressable,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import AppBackground from './src/domains/app/AppBackground';
import Menu from './src/domains/app/Menu';
import KundaliniVideo from './src/domains/kundalini/KundaliniVideo';
import OverlayingClouds from './src/domains/app/OverlayingClouds';
import Home from './src/domains/home/Home';
import Kundalini from './src/domains/kundalini/Kundalini';
import About from './src/domains/about/About';
import Agenda from './src/domains/agenda/Agenda';
import Popup from './src/domains/app/Popup';
import HexaButton from './src/domains/app/HexaButton';
import Reviews from './src/domains/reviews/Reviews';
import { FirebaseProvider } from './src/services/FirebaseProvider';
import Coaching from './src/domains/coaching/Coaching';
import Contact from './src/domains/contact/Contact';
import emailjs from '@emailjs/react-native';

export type Screens =
  | 'home'
  | 'index.html'
  | 'kundalini'
  | 'content-creation'
  | 'about'
  | 'contact'
  | 'coaching'
  | 'agenda'
  | 'reviews';

export const FADE_TRANSITION = 500;

const EMAILJS_SERVICE_ID = 'service_ge7vzzm';
const EMAILJS_TEMPLATE_ID = 'template_0mqsa0a';
const EMAILJS_PUBLIC_KEY = 'AWkF-Xh3W1u8k36ui';

// set Public Key as global settings
emailjs.init({
  publicKey: EMAILJS_PUBLIC_KEY,
});

export default function App() {
  //  Load fonts
  useFonts({
    poorRichard: require('./src/assets/fonts/poorRichard.ttf'),
    gabriela: require('./src/assets/fonts/gabriela.ttf'),
  });

  const { height } = useWindowDimensions();

  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [message, setMessage] = useState<string>();

  //  Active screen
  const [activeScreen, setActiveScreen] = useState<Screens>('home');
  const isTransitioning = useSharedValue(false);

  // Set initial screen based on URL
  useEffect(() => {
    const init = async () => {
      const initialURL = await Linking.getInitialURL();
      const parts = initialURL?.split('/');
      const initialScreen = parts?.[parts?.length - 1] as Screens;
      setActiveScreen(initialScreen || 'home');
    };
    init();
  }, []);

  const [showVideo, setShowVideo] = useState(false);

  const getScreen = () => {
    switch (activeScreen) {
      case 'home':
        return <Home isTransitioning={isTransitioning} />;
      case 'kundalini':
        return (
          <Kundalini
            showVideo={() => {
              setShowVideo(true);
            }}
            isTransitioning={isTransitioning}
          />
        );
      case 'coaching':
        return <Coaching isTransitioning={isTransitioning} />;

      case 'about':
        return <About isTransitioning={isTransitioning} />;
      case 'agenda':
        return <Agenda isTransitioning={isTransitioning} />;
      case 'reviews':
        return <Reviews isTransitioning={isTransitioning} />;
      case 'contact':
        return <Contact isTransitioning={isTransitioning} />;
      default:
        return <Home isTransitioning={isTransitioning} />;
    }
  };

  const initiateNewScreen = (screen: Screens) => {
    // if (screen === 'contact') {
    //   Linking.openURL('https://instagram.com/annemarie_sensee?utm_source=qr&igshid=dDZubjZxOXJzcnds');
    // }
    //  Start fade out
    isTransitioning.value = true;
    //  Wait for fade out
    setTimeout(() => {
      //  Set new screen
      setActiveScreen(screen);
      //  Fade in new screen
      isTransitioning.value = false;
    }, FADE_TRANSITION);
  };

  const animatedLogoStyle = useAnimatedStyle(() => {
    return {
      top: withTiming(activeScreen === 'home' || activeScreen === 'index.html' ? height / 2 - 260 : -20, {
        duration: FADE_TRANSITION,
      }),
      transform: [
        {
          scale: withTiming(activeScreen === 'home' || activeScreen === 'index.html' ? 1 : 0.4, {
            duration: FADE_TRANSITION,
          }),
        },
      ],
    };
  });

  return (
    <FirebaseProvider>
      <AppBackground>
        {/* <Octagon /> */}
        {getScreen()}

        {activeScreen !== 'agenda' && activeScreen !== 'coaching' && (
          <View style={{ position: 'absolute', bottom: 40, left: 40 }}>
            <HexaButton
              text="Boek een NRGY!"
              onPress={() => {
                initiateNewScreen('agenda');
              }}
              inverted
            />
          </View>
        )}

        {/* LOGO - visible on all screens */}
        <Animated.View
          style={[
            { width: '100%', alignItems: 'center', top: height / 2 - 260, position: 'absolute' },
            animatedLogoStyle,
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              initiateNewScreen('home');
            }}
            disabled={activeScreen === 'home' || activeScreen === 'index.html'}
          >
            <Image style={[{ width: 150, height: 150 }]} source={require('./src/assets/logo.png')} />
          </TouchableOpacity>
        </Animated.View>
        <Menu onPress={initiateNewScreen} />
        <OverlayingClouds key={'overlayingClouds'} />
        {showVideo && <KundaliniVideo setShowVideo={setShowVideo} />}
      </AppBackground>
    </FirebaseProvider>
  );
}
