import { ScrollView, Text, View, useWindowDimensions } from 'react-native';
import React, { useEffect } from 'react';
import { FADE_TRANSITION } from '../../../App';
import Animated, { SharedValue, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import Review from './Review';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const Reviews = ({ isTransitioning }: Props) => {
  const { height, width } = useWindowDimensions();

  const mounted = useSharedValue(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  const reviews = [
    {
      type: 'kundalini',
      text: `Hi Annemarie,
      
Vooraf wist ik niet wat ik kon verwachten en je advies om die verwachtingen los te laten was heel treffend ;)
Door je liefdevolle energie mocht ik uiteindelijk mooie sensaties ervaren. Dankjewel!

      - Liefs, Lisa`,
    },
    {
      type: 'kundalini',
      text: `Een mooie eerste aanraking, een ervaring met de energie van het universum.`,
    },
    {
      type: 'kundalini',
      text: `Bedankt voor deze mooie sessie Annemarie. Met zorgzame aandacht veranderingen in energie.`,
    },
    {
      type: 'kundalini',
      text: `Dankje voor deze super ervaring. Herboren!!
      
      - xxx, Trienke`,
    },
    {
      type: 'kundalini',
      text: `Dankjewel voor de fijne middag, mooie innerlijke processen en de afwisseling van de energie in mijn lijf. Ik voel me nu veel meer geaard!

      - Warme groet, Esther`,
    },
    {
      type: 'kundalini',
      text: `“Mijn eerste kundalini ervaring - een hele fijne sessie met goede muziek en fijne, rustige, warme energie. De aanrakingen van Annemarie voelen gemakkelijk en liefdevol. Ik had geen verwachtingen, maar toch zijn ze overtroffen, aanrader! 

Dankjewel Annemarie!”
      
      - Evy`,
    },
    {
      type: 'kundalini',
      text: `“Lieve Annemarie, 
      
een prachtige kundalini sessie met uitstekende muziek. De muziek had de juiste frequentie en vibratie. Dat bracht inzichten en gevoelens, waarmee ik verder kan. Ga verder op deze weg lieve Annemarie".
      
      - Alle liefs van Ada`,
    },
    {
      type: 'kundalini',
      text: `“Een prachtige muzikale reis, vanaf een heerlijk comfortabel matje. Heel kalm en liefdevol begeleid door de warme handen van Annemarie. Super gefaciliteerd, in een mooie ruimte van alle gemakken voorzien. Hartelijk bedankt voor mijn eerste kundalini kennismaking!!”
      
      - Kelly`,
    },
    {
      type: 'kundalini',
      text: `“Lieve Annemarie,

Een magische reis ervaren, de muziek zinderd door mijn lijf, emoties, bewegingen gingen vanzelf. De energie hoog, je aanrakingen warm, liefdevol en veilig! Dankjewel! Deze kundalini sessie is voor herhaling vatbaar. Je bent een fantastische facilitator. De hoge vibratie trilt nog na in mijn lijf. Dankbaar voor jou.”
      
      Liefs Conny`,
    },
    {
      type: 'kundalini',
      text: `“Lieve Annemarie,

Wat een geweldig mooie sessie! Ik voelde je energie elke keer als je langs kwam, en over de tijd van het uur+ bleef het effect langer aanhouden. Bij mijn 'focus on the light' verschenen prachtige groene kleuren, overlayed met lichbtblauw. De muziekselectie vond ik zeer goed gekozen, en de overgangen waren precies passend. Kortom, een rustgevende, prachtige ervaring. Kan ik zeker verder aanbevelen en ik kijk uit naar de volgende keer!”
      
      Liefs, je ome Rik!`,
    },
    {
      type: 'kundalini',
      text: `“Lieve Annemarie,

Wat een mooie reis was dit. Op prachtig samengestelde muziek, in een mooie en goed voelende omgeving en het vertrouwen dat je uitstraalt! Alles mag er zijn! Een hele mooie ervaring gehad waarin grote bollen met energie als het ware in mijn handen lagen. Geweldig. Ik wens je een hele mooie toekomst toe waarin je jouw kwaliteiten steeds verder uitbreidt. Volg je hart!” 
      
Veel liefs en dikke knuffel,

      Miranka`,
    },
    {
      type: 'kundalini',
      text: `Vandaag mocht ik een Kundalini NRGY sessie ontvangen van Annemarie. Ik heb al eerder NRGY sessies mogen ontvangen, maar in deze mocht ik een stuk dieper gaan dan voorheen. Normaal is mijn hoofd actief aanwezig en vandaag juist niet wat me nieuwe dingen liet ervaren.

Ik voelde me helemaal veilig en op mijn gemak bij Annemarie. Haar begeleiding was fijn en je kunt merken hoe leuk ze het zelf vindt deze sessies te faciliteren.
      
Dank je wel voor de ervaring ♥️`,
    },
  ];

  return (
    <Animated.View
      style={[{ position: 'absolute', width: '100%', paddingVertical: 100 }, animatedFadeInStyle]}
    >
      <ScrollView
        style={{
          height: height - 240,
        }}
        contentContainerStyle={{
          alignItems: 'center',
          paddingBottom: 240,
        }}
      >
        <Text
          style={{
            color: '#d60006',
            fontSize: 20,
            fontWeight: '600',
            marginBottom: 30,
            fontFamily: 'gabriela',
            alignSelf: 'flex-start',
            marginLeft: '10%',
            marginTop: 50,
          }}
        >
          Reviews
        </Text>

        <View style={{ width: '80%' }}>
          {reviews.map(({ text, type }, index) => {
            return (
              <Review text={text} key={`${type}-${index}`} />
              // <View
              //   style={{
              //     width: 300,
              //     height: 300,
              //     marginVertical: 20,
              //     alignItems: 'center',
              //     justifyContent: 'center',
              //   }}
              // >
              //   <Octagon />
              //   <View style={{ alignItems: 'center', height: 300, paddingTop: 30 }}>
              //     <Image
              //       source={require('../../assets/kundalini-snake.png')}
              //       style={{
              //         width: 300 / 6,
              //         height: 300 / 6,
              //         resizeMode: 'contain',
              //         marginBottom: 300 / 30,
              //       }}
              //     />
              //     <Text style={{ textAlign: 'center', width: '75%', fontFamily: 'gabriela' }}>{text}</Text>
              //   </View>
              // </View>
              // <View
              //   style={{
              //     height: 300,
              //     marginTop: 50,
              //     alignItems: 'center',
              //     backgroundColor: 'green',
              //   }}
              // >
              //   <Octagon />
              //   <Image
              //     source={require('../../assets/kundalini-snake.png')}
              //     style={{
              //       width: 300 / 6,
              //       height: 300 / 6,
              //       resizeMode: 'contain',
              //       marginBottom: 300 / 30,
              //       backgroundColor: 'red',
              //     }}
              //   />
              //   <Text style={{ width: '100%', textAlign: 'center' }}>Test</Text>
              // </View>
            );
          })}
        </View>
      </ScrollView>
    </Animated.View>
  );
};

export default Reviews;
