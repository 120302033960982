import { ScrollView, Text, useWindowDimensions } from 'react-native';
import React, { useEffect } from 'react';
import Animated, {
  Easing,
  SharedValue,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { FADE_TRANSITION } from '../../../App';

interface Props {
  isTransitioning: SharedValue<boolean>;
}

const Home = ({ isTransitioning }: Props) => {
  const logoOpacity = useSharedValue(0);
  const logoScale = useSharedValue(0.6);

  useEffect(() => {
    logoOpacity.value = withTiming(1, { duration: 2000, easing: Easing.bezier(0.25, 0.1, 0.25, 0.1) });
    logoScale.value = withTiming(1, { duration: 2000, easing: Easing.bezier(0.25, 0.1, 0.25, 0.1) });
  }, []);

  const { height } = useWindowDimensions();

  const mounted = useSharedValue(false);

  const animatedFadeInStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isTransitioning.value ? 0 : mounted.value ? 1 : 0, { duration: FADE_TRANSITION }),
    };
  });

  useEffect(() => {
    mounted.value = true;
    return () => {
      mounted.value = false;
    };
  }, []);

  return (
    <Animated.View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          height: height,
          position: 'absolute',
          width: '100%',
        },
        animatedFadeInStyle,
      ]}
    >
      <Text
        style={{
          fontFamily: 'poorRichard',
          fontSize: 80,
          top: height / 2 - 120,
          width: '100%',
          textAlign: 'center',
          position: 'absolute',
        }}
      >
        Sensee
      </Text>
      <ScrollView
        style={{
          height: height / 2 - 120,
          width: '100%',
          position: 'absolute',
          top: height / 2,
        }}
        contentContainerStyle={{
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'gabriela',
            textAlign: 'center',
            fontSize: 16,
            width: '80%',
          }}
        >
          Heb je het gevoel dat je uit balans bent? Voel je dat er meer potentie in je zit? Verlang je naar
          een diep gevoel van zelfliefde? Of wil je je simpelweg beter voelen op diverse levels? <br />
          <br />
          Het is een eer om met je te mogen werken en een facilitator te zijn in jouw heling en transformatie.
          <br />
          <br />
          Liefs, Annemarie
        </Text>
      </ScrollView>
    </Animated.View>
  );
};

export default Home;
